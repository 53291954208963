import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FlashMessage } from '@reactiveonline/frontend_shared_components'

export default function TokenizedCheckoutForm({
  paymentMethodId, setCurrentCardPath, cardsPath, setIsLoading,
  orderId, type, addNewCardText, setAvailableCards
}) {
  const [cards, setCards] = useState([])
  const [addNewCard, setAddNewCard] = useState(false)
  const [paymentProviderIframe, setPaymentProviderIframe] = useState('')
  const flashMessageRef = useRef(null)

  useEffect(() => {
    Rails.ajax({
      type: 'GET',
      url: `${cardsPath}?payment_method_id=${paymentMethodId}`,
      dataType: 'json',
      success: res => {
        if (res.cards) {
          setCards(res.cards)
          setAddNewCard(res.cards.length == 0)
        }
      },
      error: res => {
        if (res.error) {
          flashMessageRef.current.show(res.error, 'error')
        }
      },
      complete: res => {
        setIsLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (cards) {
      setAvailableCards(cards.length > 0)
    } else {
      setAvailableCards(false)
    }
  }, [cards])

  useEffect(() => {
    if (addNewCard) {
      Rails.ajax({
        type: 'GET',
        url: `/payment_methods/${paymentMethodId}/payment_iframe?type=${type}&number=${orderId}`,
        dataType: 'json',
        success: res => {
          if (res.paymentIframe) {
            setPaymentProviderIframe(res.paymentIframe)
          }
        },
        error: res => {
          if (res.error) {
            flashMessageRef.current.show(res.error, 'error')
          }
        }
      })
    }

  }, [addNewCard])

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
        window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  const handleMessage = (event) => {
    let data = {}
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      setAddNewCard(false)
      setPaymentProviderIframe('')
      return
    }

    if (data['status'] === "AUTHORIZED") {
      setAddNewCard(false)
      setPaymentProviderIframe('')
      setIsLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${cardsPath}?payment_method_id=${paymentMethodId}`,
        dataType: 'json',
        success: res => {
          if (res.cards) {
            setCards(res.cards)
          }
        },
        error: res => {
          if (res.error) {
            flashMessageRef.current.show(res.error, 'error')
          }
        },
        complete: res => {
          setIsLoading(false)
        }
      })
    }
  }

  function setPrimaryCard(card) {
    if (!card.current) {
      setIsLoading(true)
      let fd = new FormData()
      fd.append('card_details[id]', card.id)
      fd.append('card_details[payment_method_id]', paymentMethodId)

      Rails.ajax({
        type: 'POST',
        url: setCurrentCardPath,
        dataType: 'json',
        data: fd,
        success: res => {
          if (res.cards) {
            setCards(res.cards)
          }
        },
        error: res => {
          if (res.error) {
            flashMessageRef.current.show(res.error, 'error')
          }
        },
        complete: res => {
          setIsLoading(false)
        }
      })
    }
  }

  if (cards.length) {
    return (
      <>
        <div className='tokenized-cards-wrapper'>
          { cards.map( card => (
            <div
              key={ card.id }
              className={ `tokenized-card ${ card.current ? 'current' : '' }` }
              onClick={ () => setPrimaryCard(card) }
            >
              <img src={ `https://storage.googleapis.com/reactive_files_eu/payment_processors/card_issuers/${ card.issuer }.svg` } style={{ width: 50 }}/>
              { ['amex', 'diners', 'discover', 'maestro', 'mastercard', 'visa'].includes(card.issuer) &&
                <div className='tokenized-card-pan'>
                  { `********${ card.panLast }` }
                </div>
              }
            </div>
          ))}

          { addNewCard ?
            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: paymentProviderIframe }}/>

          :
            <div className='add-card'>
              <button
                type='button'
                className='button plain'
                onClick={ () => setAddNewCard(true) }
              >
                <span>
                  { addNewCardText }
                </span>
              </button>
            </div>
          }
        </div>

        <FlashMessage ref={ flashMessageRef } />
      </>
    )

  } else {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: paymentProviderIframe }}/>
        <FlashMessage ref={ flashMessageRef } />
      </>
    )
  }
}
