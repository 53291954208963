import React, { useState, useEffect } from 'react'
import PaymentStep from './PaymentStep'
import { AuthorizableModal, Loader, SelectInput } from '@reactiveonline/frontend_shared_components'
import { validatePhoneNumber } from '@reactiveonline/frontend_shared_components/utils'
import Summary from './Summary'
import ErrorMessage from '../shared/ErrorMessage'

export default function PaymentLink(props) {
  const { appProps, disabledCheckout, agreements, legalTerms, reservation } = props
  const { translations } = appProps

  const [billingAddress, setBillingAddress] = useState(props.userAddress)
  const [notes, setNotes] = useState(reservation.notes)
  const [countries, setCountries] = useState([])
  const [currentCountry, setCurrentCountry] = useState(null)
  const [currentState, setCurrentState] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect( () => {
    setLoading(true)

    Rails.ajax({
      url: appProps.getCountriesPath,
      type: 'GET',
      success: res => {
        const formattedCountries = res.available_countries
        const countrySelected = formattedCountries.filter(c => c.value == billingAddress.country_numeric_code)[0] || formattedCountries[0]
        setCountries(formattedCountries)
        setCurrentCountry(countrySelected)

        if (billingAddress.state_region_code && countrySelected.states_required) {
          const stateSelected = countrySelected.states.filter(c => c.value == billingAddress.state_region_code)[0] || null
          setCurrentState(stateSelected)
        }
        setLoading(false)
      }
    })
  }, [])

  function populateFormData(formData) {
    formData.append('reservation[billing_address_attributes][full_name]', billingAddress.full_name)
    formData.append('reservation[billing_address_attributes][phone]', billingAddress.phone)
    formData.append('reservation[billing_address_attributes][mobile_phone]', billingAddress.mobile_phone)
    formData.append('reservation[billing_address_attributes][city]', billingAddress.city)
    formData.append('reservation[billing_address_attributes][postal_code]', billingAddress.postal_code)
    formData.append('reservation[billing_address_attributes][street]', billingAddress.street)
    formData.append('reservation[billing_address_attributes][country_numeric_code]', currentCountry ? currentCountry.value : null)
    formData.append('reservation[billing_address_attributes][state_region_code]', currentState ? currentState.value : null)

    formData.append('reservation[notes]', notes)
  }

  function setCountry(country) {
    setCurrentCountry(country)
    setCurrentState(null)
  }

  function updateBillingAddress(state) {
    setBillingAddress( prevState => ({ ...prevState, ...state }) )
  }

  function handleChangePhone(value, key) {
    if (value.match(/^(\+?\d{0,20})?$/)) {
      updateBillingAddress({ [key]: value })
    }
  }

  function canProceed() {
    document.querySelectorAll('[id$="-error"]').forEach( errorElement => errorElement.remove() )
    document.querySelectorAll('input').forEach( element => element.style.borderColor = '' )

    let noErrors = true
    const {
      city, full_name, postal_code, state_region_code, street, phone,
      mobile_phone
    } = billingAddress

    if (!full_name || full_name.length === 0) {
      ErrorMessage('billing-full-name', 'red', 'billing-full-name-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if ((!phone && !mobile_phone) || (phone && !validatePhoneNumber(phone))) {
      ErrorMessage('billing-phone', 'red', 'billing-phone-error', translations.flash_messages.phone_invalid, 'red')
      noErrors = false
    }

    if ((!phone && !mobile_phone) || (mobile_phone && !validatePhoneNumber(mobile_phone))) {
      ErrorMessage('billing-mobile-phone', 'red', 'billing-mobile-phone-error', translations.flash_messages.phone_invalid, 'red')
      noErrors = false
    }

    if (!street || street.length === 0) {
      ErrorMessage('billing-street', 'red', 'billing-street-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!city || city.length === 0) {
      ErrorMessage('billing-city', 'red', 'billing-city-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!postal_code || postal_code.length === 0) {
      ErrorMessage('billing-postal-code', 'red', 'billing-posta-code-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (!currentCountry) {
      ErrorMessage('billing-country', 'red', 'billing-country-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    if (currentCountry && currentCountry.states_required && !currentState) {
      ErrorMessage('billing-state', 'red', 'billing-state-error', translations.flash_messages.fill_all_required_fields, 'red')
      noErrors = false
    }

    return noErrors
  }

  return (
    <>
      <div className='main-container'>
        { props.disabledCheckout ?
          <div className="main-container">
            <div className="not-found-wrapper flex-box flex-column items-center content-center">
              <h2>
                { translations.general.checkout_closed }
              </h2>
            </div>
          </div>
          :
          ( !appProps.user && reservation.rental.policy ?
            <AuthorizableModal
              appProps={ appProps }
              authorizableScreen={ 'login' }
            />
            :
            <div className='checkouts-wrapper'>
              <Summary
                appProps={ appProps }
                currentRental={ reservation.rental }
                cost={ reservation.cost }
              />
              <div className='checkout-container'>
                <div className="card checkout-step flex-box flex-column">
                  <h3><b>{ translations.reservations.contact_information_title }</b></h3>
                  <div className='step-editor flex-box flex-wrap'>
                    <div className='form'>
                      <div className="field">
                        <div className='flex-box items-center'>
                          <label>
                            { translations.reservations.email }
                          </label>
                          <div style={{ marginLeft: 5 }}>*</div>
                        </div>
                          <input
                            disabled
                            type='email'
                            value={ appProps.currentUserEmail || reservation.email }
                          />
                      </div>

                      <div className="field">
                        <div className='flex-box items-center'>
                          <label>
                            { translations.reservations.full_name }
                          </label>
                          <div style={{ marginLeft: 5 }}>*</div>
                        </div>

                        <input
                          id='billing-full-name'
                          type='text'
                          value={ billingAddress.full_name || '' }
                          onChange={ event => updateBillingAddress({ full_name: event.target.value }) }
                        />
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.phone }
                            </label>

                            { (billingAddress.phone || !billingAddress.mobile_phone) &&
                              <div style={{ marginLeft: 5 }}>*</div>
                            }
                          </div>

                          <input
                            id='billing-phone'
                            type='tel'
                            value={ billingAddress.phone || '' }
                            onChange={ event => handleChangePhone(event.target.value, 'phone') }
                          />
                        </div>

                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { appProps.translations.reservations.mobile }
                            </label>

                            { (billingAddress.mobile_phone || !billingAddress.phone) &&
                              <div style={{ marginLeft: 5 }}>*</div>
                            }
                          </div>

                          <input
                            id='billing-mobile-phone'
                            type='tel'
                            value={ billingAddress.mobile_phone || '' }
                            onChange={ event => handleChangePhone(event.target.value, 'mobile_phone') }
                          />
                        </div>
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.address }
                            </label>
                            <div style={{ marginLeft: 5 }}>*</div>
                          </div>
                          <input
                            id='billing-street'
                            type='text'
                            value={ billingAddress.street || '' }
                            onChange={ event => updateBillingAddress({ street: event.target.value }) }
                          />
                        </div>

                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.city }
                            </label>
                            <div style={{ marginLeft: 5 }}>*</div>
                          </div>
                          <input
                            id='billing-city'
                            type='text'
                            value={ billingAddress.city || '' }
                            onChange={ event => updateBillingAddress({ city: event.target.value }) }
                          />
                        </div>
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.postal_code }
                            </label>
                            <div style={{ marginLeft: 5 }}>*</div>
                          </div>
                          <input
                            type='text'
                            id='billing-postal-code'
                            value={ billingAddress.postal_code || '' }
                            onChange={ event => updateBillingAddress({ postal_code: event.target.value }) }
                          />
                        </div>

                        <div className="field">
                          <SelectInput
                            wrapperClasses='checkout-step'
                            label={ `${ translations.reservations.country } *` }
                            options={ countries }
                            currentOption={ currentCountry ? currentCountry.value : null }
                            setCurrentOption={ setCountry }
                          />
                        </div>

                        { currentCountry && currentCountry.states_required &&
                          <div className="field">
                            <SelectInput
                              wrapperClasses='checkout-step'
                              label={ `${ translations.reservations.state } *` }
                              options={ currentCountry.states }
                              currentOption={ currentState ? currentState.value : null }
                              setCurrentOption={ setCurrentState }
                            />
                          </div>
                        }

                        <div className="field notes-wrapper">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.reservation_notes }
                            </label>
                          </div>
                          <textarea
                            value={ notes || '' }
                            onChange={  event => setNotes(event.target.value) }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <PaymentStep
                  appProps={ appProps }
                  indirect={ !!reservation.rental.policy }
                  dateRange={ reservation.date_range }
                  stripePublishableKey = { props.stripePublishableKey }
                  orderId = { props.orderId }
                  type = { props.type }
                  paymentIframePath = { props.paymentIframePath }
                  cards = { props.cards }
                  setCurrentCardPath = { props.setCurrentCardPath }
                  cardsPath = { props.cardsPath }
                  addNewCardText = { props.addNewCardText }
                  guestCheckout = { !appProps.currentUserEmail }
                  reservationPath={ props.payByLinkPath }
                  chargeAtTimeOfBooking = { !!props.chargeAtTimeOfBooking }
                  billingDetails = { {
                    address: {
                      city: billingAddress.city,
                      country: billingAddress.country_alpha2_code,
                      line1: billingAddress.street,
                      postal_code: billingAddress.postal_code,
                      state: countries.find( country => country.value === billingAddress.country_numeric_code )?.states?.find( state => state.value === billingAddress.state_region_code )?.label
                    },
                    email: reservation.email,
                    name: billingAddress.full_name,
                    phone: billingAddress.mobile_phone || billingAddress.phone
                  } }
                  agreements={ agreements }
                  legalTerms={ legalTerms }
                  paymentLink
                  populateFormData={ populateFormData }
                  canProceed={ canProceed }
                />
              </div>
            </div>
          )
        }
      </div>

      { loading && <Loader size='large' fullPage /> }
    </>
  )
}
