import debounce from "lodash.debounce"

export function getCookieExpirationDate(days) {
  const expiration = new Date()
  expiration.setDate(expiration.getDate() + days)
  return expiration
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }

    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 45,
    minHeight: 45,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 45
  })
}

export function customReactSelectTheme(theme) {
  return {
    ...theme,
    colors: {
    ...theme.colors,
      primary50: '#f7f7f7'
    }
  }
}

export function blogImageUrls(imagesArray) {
  const blogImages = imagesArray.length > 0 ? imagesArray.map( image => {
      return {
        original: image.source,
        thumbnail: '',
        fullscreen: '',
      }
    }
    ) : [{
      original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
      thumbnail: '',
      fullscreen: ''
    }]
  return ( blogImages )
}

export function debounceEventHandler(...args) {
  const debounced = debounce(...args)
  return (e) => {
    if(typeof e.persist === 'function') e.persist()
    return debounced(e)
  }
}

export function formatPriceWithCurrency(amount, currency) {
  if(currency && currency.symbol_first) {
    return `${currency.symbol}${amount}`
  } else if(currency) {
    return `${amount} ${currency.symbol}`
  } else {
    return `€${amount}`
  }
}

export function urlParamsAsObject(queryString) {
  const urlParams = new URLSearchParams(queryString)
  const entries = urlParams.entries()

  let params = {}
  for(const entry of entries) {
    params[entry[0]] = entry[1]
  }

  return params
}

function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

// EXISTS FOR FRONTEND COMPONENTS | DUPLICATE ON SHARED ADMIN PACKAGES
export function generateYoutubeLinkWithAttributes(videoId, videoSettings) {
  let link = `https://www.youtube.com/embed/${ videoId }?`
  Object.keys(videoSettings).map( attribute => {
    switch (attribute) {
      case 'showRelated':
        link += `&rel=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showCaptions':
        link += videoSettings[attribute] ? '&cc_load_policy=1&cc_lang_pref=en' : ''
        break
      case 'autoplay':
        link += `&autoplay=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'loop':
        link += videoSettings[attribute] ? `&loop=1&playlist=${ videoId }` : ''
        break
      case 'mute':
        link += `&mute=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'controls':
        link += `&controls=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showFullscreen':
        link += `&fs=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showLogo':
        link += `&modestbranding=${ !videoSettings[attribute] ? 1 : 0 }`
        break
    }
  })

  return link
}

export function toURLEncoded(element) {
  var key, list;
  list = [];
  if ('object' === typeof element) {
    for (key in element) {
      key = key;
      if (element.hasOwnProperty(key)) {
        list.push(key + '=' + encodeURIComponent(element[key]));
      }
    }
  }
  return list.join('&');
}

export function showFlashMessage(message, mode) {
  const elmId = 'flash-message'
  let div = document.getElementById(elmId)
  if(div) {
    div.remove()
  }
  div = document.createElement('div')
  div.setAttribute('id', elmId)

  ReactDOM.render(
    <FlashMessage flashMessage={message} flashMessageMode={mode} />,
    document.body.appendChild(div)
  )
}

export function getTranslatableField(entity, field, currentLocale, fallback = false) {
  if(entity.translations) {
    let currentTranslatableFields
    if(currentLocale) {
      currentTranslatableFields = entity.translations.filter(t => t.locale === currentLocale)[0]
    } else {
      currentTranslatableFields = entity.translations[0]
    }

    const firstTranslatableFields = entity.translations.filter(t => t[field])[0]

    if(!fallback) {
      if(currentTranslatableFields) {
        return currentTranslatableFields[field] || ''
      } else {
        return ''
      }
    } else {
      if(currentTranslatableFields && currentTranslatableFields[field]) {
        return currentTranslatableFields[field] || ''
      } else if(firstTranslatableFields) {
        return firstTranslatableFields[field] || ''
      }
    }
  }

  return ''
}